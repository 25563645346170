<template>
  <div class="crypto-talent-dashboard-card" data-cy="account-card">
    <div class="crypto-talent-dashboard-card-header">
      {{ $t("pages.business.account.header") }}
    </div>

    <div class="crypto-talent-dashboard-card-main-divider md:mt-5 mt-3" />

    <div class="md:mt-[30px] mt-[20px] font-semibold text-lg leading-6">
      {{ $t("pages.business.account.resumeCreditsHeader") }}
    </div>

    <div
      class="md:mt-5 mt-4 w-full px-4 bg-[#230938b3] backdrop-blur-md rounded-[10px] shadow-md"
    >
      <div
        class="crypto-talent-hover h-11 flex items-center text-sm leading-5 tracking-wide text-[#01fce5] transition-opacity border-b border-[#b500fd33]"
        data-cy="account-card-credits-history"
        @click="$router.push('/business-dashboard/account/history-cv-credits')"
      >
        {{ $t("pages.business.account.cvCreditsHistory") }}
      </div>
      <div
        class="crypto-talent-hover h-11 flex items-center text-sm leading-5 tracking-wide text-[#01fce5] transition-opacity"
        data-cy="account-card-credits-issued-history"
        @click="
          $router.push('/business-dashboard/account/history-cv-credits-issued')
        "
      >
        {{ $t("pages.business.account.cvCreditsIssuedHistory") }}
      </div>
    </div>

    <div class="md:mt-[30px] mt-[20px] font-semibold text-lg leading-6">
      {{ $t("pages.business.account.invoicesHeader") }}
    </div>

    <div
      class="w-full min-h-[160px] mt-4 px-4 pt-4 pb-6 bg-[#230938b3] backdrop-blur-md md:text-base text-xs rounded-[10px] shadow-md"
    >
      <div
        class="account-card-invoices-card-header font-semibold md:text-base text-xs leading-6 flex justify-evenly"
      >
        <div class="md:flex-1 flex-[1.35]">
          {{ $t("pages.business.account.invoiceNumber") }}
        </div>
        <div class="flex-1">
          {{ $t("pages.business.account.invoiceAmount") }}
        </div>
        <div class="flex-1">{{ $t("pages.business.account.invoiceDate") }}</div>
      </div>

      <div class="crypto-talent-dashboard-card-main-divider mt-4" />

      <div v-if="loading" class="crypto-talent-loading-ring mx-auto mt-8"></div>

      <div
        v-for="invoice in invoices.slice(0, 4)"
        :key="invoice.id"
        class="account-card-invoices-card-row crypto-talent-hover flex justify-evenly h-[46px] lg:text-base text-sm"
        data-cy="account-card-invoices-card-row"
        @click="goToInvoice(invoice)"
      >
        <div class="crypto-talent-highlight">
          {{ invoice.number }}
        </div>
        <div>&euro;{{ invoice.subtotal }}</div>
        <div>
          {{ invoice.date }}
        </div>
      </div>

      <div
        v-if="invoices.length > 0"
        class="crypto-talent-hover md:mt-5 mt-4 text-right md:text-sm text-xs leading-5 tracking-wide underline text-[#01fce5]"
        data-cy="account-card-all-invoices"
        @click="$router.push('/business-dashboard/account/invoices')"
      >
        {{ $t("pages.business.account.allInvoices") }}
      </div>
      <div
        v-else-if="!loading"
        class="w-full min-h-[80px] mt-4 flex items-center justify-center text-center text-sm"
      >
        {{ $t("pages.business.account.noInvoices") }}
      </div>
    </div>

    <div
      class="mt-[30px] w-full flex justify-between md:flex-row flex-col md:gap-2 gap-5"
    >
      <div
        class="w-full md:max-w-[372px] md:min-h-[210px] min-h-[160px] py-4 px-[18px] bg-[#240939b3] border border-[#b500fd33] backdrop-blur-md rounded-[10px] shadow-md"
      >
        <div class="flex justify-between font-semibold text-lg leading-6">
          {{ $t("pages.business.account.billingAddress") }}

          <div
            class="crypto-talent-hover"
            data-cy="account-card-change-billing-address"
            :title="$t('shared.edit')"
            @click="$router.push('/business-dashboard/account/billing-address')"
          >
            <edit-svg
              :class="{
                'crypto-talent-svg-medium': $root.isHorizontalMobile,
              }"
            />
          </div>
        </div>

        <div class="mt-9 md:text-base text-sm leading-5">
          {{ businessUser?.business?.billingAddress?.email }}<br />
          {{ businessUser?.business?.billingAddress?.name }}<br />
          {{ businessUser?.business?.billingAddress?.companyName }}<br />
          {{ businessUser?.business?.billingAddress?.address }},
          {{ businessUser?.business?.billingAddress?.postalCode }}
        </div>
      </div>

      <div
        class="w-full md:max-w-[372px] md:min-h-[210px] min-h-[160px] py-4 px-[18px] bg-[#240939b3] border border-[#b500fd33] backdrop-blur-md rounded-[10px] shadow-md"
      >
        <div class="font-semibold text-lg leading-6">
          {{ $t("pages.business.account.ourData") }}
        </div>
        <div class="mt-9 md:text-base text-sm leading-5">
          <a
            class="crypto-talent-link crypto-talent-highlight underline underline-offset-4"
            href="mailto:support@cryptotalent.nl"
          >
            support@cryptotalent.nl
          </a>
          <br />
          Weteringstraat 60, 3741TH, Baarn
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import moment from "moment";

import httpClient, { getBusinessUser } from "@/http_client";

import EditSvg from "@/assets/svg/Svg/All/linear/edit.svg?component";

import type { BusinessUserFullyPopulated } from "@/models/business.user";

export default defineComponent({
  name: "AccountCard",
  components: {
    EditSvg,
  },
  data() {
    return {
      businessUser: null as BusinessUserFullyPopulated | null,
      invoices: [] as any[],
      loading: false,
      extendSubscriptionToggle: false,
      moment,
    };
  },
  async mounted() {
    try {
      this.loading = true;

      this.businessUser = await getBusinessUser();

      const { data: invoices } = await httpClient.get("/business/invoices");
      this.invoices = invoices.map((invoice: any) => ({
        id: invoice.id,
        number: invoice.number,
        date: moment.unix(invoice.created).format("DD-MM-YYYY"),
        subtotal: Number(invoice.subtotal / 100).toFixed(2),
      }));
    } catch (error) {
      console.error(error);
      this.invoices = [];
    } finally {
      this.loading = false;
    }
  },
  methods: {
    goToInvoice(invoice: any) {
      const routeData = this.$router.resolve({
        name: "business-dashboard-account-invoices-detail",
        params: { id: invoice.id },
      });

      window.open(`${routeData.href}`, "_blank");
    },
  },
});
</script>

<style lang="scss" scoped>
.account-card-invoices-card-row {
  border-bottom: 1px solid rgba(181, 0, 253, 0.2);

  & > div {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;

    @media screen and (max-width: 430px) {
      &:first-of-type {
        flex: 1.35;
      }
    }
  }
}
</style>
